import React, { ReactElement } from 'react'

import Layout from '@/components/layout'

function Page(): ReactElement {
  return (
    <Layout>
      <h1>Nålebehandling</h1>
      <p>
        Nålebehandling Kiropraktoren bruker nålebehandling dersom det er nødvendig for å få stive muskler til å slappe
        mer av. Dette gjøres ved at kiropraktoren lokaliserer triggerpunkt i muskelen og stikker en tynn nål i dette
        punktet. Om kiropraktoren treffer får pasienten en liten rykning i muskelfibrene og muskelen slapper mer av.
        Dette oppleves av de aller fleste som ikke mer enn et mildt ubehag.
      </p>
    </Layout>
  )
}

export default Page
